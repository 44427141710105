@use "../global/variables" as *;
//@use "variables";

.navbar-environment-label {
  position: absolute;
  right: 0;
  height: 100%;
  line-height: 2;
  background: $bg-color__dev;
  color: white;
  padding: 8px;
}
@media (max-width: 768px) {
  .navbar-environment-label{
    position: relative;
  }
  
}
