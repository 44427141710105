.heading {
    margin-top: 20px;
    margin-bottom: 15px;
    text-align: center;
}

.product-button {
    width: 200px;
    padding: 12px;
    font-size: 22px;
}

@media (max-width: 600px) {
    .product-button {
        width: 100%;
        font-size: 16px;
        float: left;
    }
}

.form {
    width: 90%;
    margin: 25px auto;

    &__buttons {
        display: flex;
        width: 100%;
        margin-top: 15px;
    }
}