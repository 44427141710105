.body {
    align-items: center;
}

.header {
    font-size: large;
    margin-bottom: 5%;
    text-align: center;
}

.charts {
    display: inline-flex;
    align-items: center;
    grid-template-columns: 1fr 1fr;
    width: 50%;
}

.doughnut {
    width: 100%;
    height: 100%;
    margin-right: 3rem;
}


.filters {
    margin-top: 3rem;
    margin-bottom: 3rem;
    width: 100%;


}

.table_header {
    max-width: 100%;
    overflow-y: hidden;
}

.number {
    margin-left: 40%;
}