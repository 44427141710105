.width-100 {
  width: 100%;
}

.margin-auto {
  margin: auto;
}

.margin-left-auto {
  margin-left: auto;
}

.margin-right-auto {
  margin-right: auto;
}

.text-align-center {
  text-align: center;
}
