.row {
    font-size: large;
    font-weight: 700;
}

.trend {
    padding: 0;
    margin: 0;
    align-items: center;
    align-self: center;
    align-content: center;

}

.trendContent {
    margin-left: 0;
    margin-right: 30%;
}

.angleUp {
    color: green;
    font-size: large;
}

.angleDown {
    color: red;
    font-size: large;
}

.equal {
    color: orange;
    font-size: large;
}