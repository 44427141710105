@use "./variables" as *;

/* Provide sufficient contrast against white background */

a {
  color: $link-color;
}

code {
  color: #e01a76;
}

// Buttons
.btn-primary {
  background-color: $btn-color__primary;
  border: 0;

  &:hover,
  &:active,
  &:focus {
    background-color: $btn-color__primary--hover;
  }
}

.btn-secondary {
    background: $btn-color__secondary;

    &:hover,
    &:active,
    &:focus {
        background-color: $btn-color__secondary--hover;
    }
}

// Navbar
.navbar {
  padding: 5px;
}

// Forms

label {
  margin-bottom: 5px;
}

.form-group {
  margin-bottom: 15px;
}

// FONT AWESOME ICONS

button .svg-inline--fa {
    font-size: 14px;
    margin-right: 4px;
}
