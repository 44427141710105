﻿// Colors
$color__white: #fff;
$color__green: #090;
$color__grey: #666;

// Links
$link-color: #0366d6;

// Buttons
$btn-color__primary: $color__green;
$btn-color__primary--hover: #007c00;
$btn-color__secondary: $color__grey;
$btn-color__secondary--hover: #555;

// Environment
$bg-color__dev: #ff1493;